import { reactive } from 'vue'

export default class Punchcards
{

    constructor( core )
    {

        if( !Punchcards.instance )
        {

            this.logger = core.getLogger()
            this.client = core.getClient()
            this.store = core.getStore()
            this.settings = core.getSettings()

            this.getState = ( key ) => {
                return core.getState( key )
            }

            this.setupPunchcards()

            this.updateTimer = setInterval( () =>
            {
                this.refresh()
            }, 10000 )

            this.refresh()

            this.ready = false

            Punchcards.instance = this

        }

        return Punchcards.instance

    }

    setupPunchcards()
    {

        this.cardsInitialized = false

        this.punchcards = reactive( {
            cards               : {},
            lines               : {},
            activeMonth         : false,
            activeMonthFormatted: false,
            activeCard          : -1,
            activeLine          : -1
        } )

    }

    refresh( force )
    {
        return new Promise( resolve =>
        {
            if( ( force === true || true !== this.getState( 'punchcardEditorOpen' ) )
                && null !== this.store.getters.sessionId )
            {
                this.getPunchcards()
                    .then( () =>
                    {
                        console.log( 'REFRESHED' )
                        return resolve()
                    } )
            }
            else
            {
                return resolve()
            }
        } )
    }

    waitReady()
    {
        return new Promise( resolve =>
        {
            if( this.ready )
            {
                return resolve()
            }
            setTimeout( () =>
            {
                return resolve( this.waitReady() )
            }, 300 )
        } )
    }

    setScope( display )
    {
        this.display = display
    }

    compile( punchcard )
    {

        let compiled = {}
        for( let i in Object.keys( punchcard ) )
        {

            let id = Object.keys( punchcard )[ i ],
                tsmp_start, tsmp_end, isOpen, id_punchline, edited

            compiled[ id ] = []

            for( let r in punchcard[ id ] )
            {
                let row = punchcard[ id ][ r ]
                id_punchline = row.id
                edited = row.record_edited === '1'

                switch( row.record_type )
                {
                    case 'TIME_START':
                        isOpen = true
                        tsmp_start = new Date( row.datetime_event ).getTime()
                        if( 0 < r )
                        {
                            compiled[ id ].push( {
                                id    : id_punchline,
                                from  : tsmp_end,
                                until : tsmp_start,
                                type  : 'PAUSE',
                                edited: false,
                                closed: true
                            } )
                        }
                        break
                    case 'TIME_LEAVE':
                        isOpen = false
                        tsmp_end = new Date( row.datetime_event ).getTime()
                        compiled[ id ].push( {
                            id    : id_punchline,
                            from  : tsmp_start,
                            until : tsmp_end,
                            type  : 'WORK',
                            edited: edited ? 'end' : false,
                            closed: true
                        } )
                        break
                }
            }

            if( isOpen )
            {
                compiled[ id ].push( {
                    id    : id_punchline,
                    from  : tsmp_start,
                    until : Date.now(),
                    type  : 'WORK',
                    edited: edited ? 'start' : false,
                    closed: false
                } )
            }

        }

        return compiled

    }

    prepareTotals( card, rows )
    {

        let total = {
            tsmpStart : 0,
            tsmpEnd   : 0,
            timeStart : '--:--',
            timeEnd   : '--:--',
            isOpen    : false,
            edited    : [],
            pauseTotal: 0,
            workTotal : 0,
            timeTotal : '--:--',
            diffTotal : '--:--',
            diffSign  : ''
        }

        let workday = -1

        for( let r in rows )
        {

            let row = rows[ r ]
            if( false !== row.edited )
            {
                total.edited.push( row.edited )
            }
            if( 0 === parseInt( r ) )
            {
                if( -1 === workday )
                {
                    workday = new Date( row.from ).getDay()
                }
                total.tsmpStart = row.from
                total.timeStart = this.formatTime( total.tsmpStart )
            }
            if( parseInt( r ) === ( rows.length - 1 ) )
            {
                total.tsmpEnd = row.until
                total.timeEnd = this.formatTime( total.tsmpEnd )
                total.isOpen = !row.closed
            }

            if( 'PAUSE' === row.type )
            {
                total.pauseTotal += ( row.until - row.from )
            }
            if( 'WORK' === row.type )
            {
                total.workTotal += ( row.until - row.from )
            }
        }

        this.punchcards.lines[ card ].grandTotal.pause += total.pauseTotal
        this.punchcards.lines[ card ].grandTotal.work += total.workTotal

        total.timePause = this.millisToTime( total.pauseTotal )
        total.timeTotal = this.millisToTime( total.workTotal )

        let todo = this.settings.get( 'hours-days' )
        if( undefined !== todo )
        {

            let hours  = ( todo[ workday ] * 60 * 60 ) * 1000,
                diff   = total.workTotal - hours,
                factor = 0 > diff ? -1 : 1

            let diffTime = this.millisToTime( diff * factor ),
                diffSIgn = 0 < factor ? '+' : '-'

            this.punchcards.lines[ card ].grandTotal.diff += diff
            total.diffTotal = diffTime
            total.diffSign = diffSIgn
            total.diffMillis = diff

        }
        else
        {
            total.diffTotal = 0
            total.diffMillis = 0
        }

        return total

    }

    formatTime( tsmp )
    {
        let d = new Date( tsmp ),
            h = d.getHours(),
            m = d.getMinutes()

        return ( ( 10 > h ? '0' : '' ) + h + ':' + ( 10 > m ? '0' : '' ) + m )
    }

    millisToTime( millis )
    {

        if( 0 > millis )
        {
            millis = millis * -1
        }

        let seconds = ( millis / 1000 ),
            hours   = Math.floor( seconds / ( 60 * 60 ) ),
            minutes = Math.ceil( ( seconds - ( hours * 60 * 60 ) ) / 60 )

        if( 60 === minutes )
        {
            hours++
            minutes = 0
        }

        return hours + ':' + ( 10 > minutes ? '0' : '' ) + minutes

    }

    getPunchlinesForCard( card )
    {

        return new Promise( resolve =>
        {

            this.client
                .request( {
                    action: 'punchlines.getPunchcard',
                    months: card
                } )
                .then( response =>
                {

                    let punchcard         = {},
                        compiledPunchcard = {},
                        lastPunchline     = 0,
                        rows              = []

                    if( undefined !== response.rows )
                    {
                        for( let r in response.rows )
                        {

                            let row = response.rows[ r ]
                            if( lastPunchline !== parseInt( row.id_punchline ) )
                            {

                                lastPunchline = parseInt( row.id_punchline )
                                punchcard[ lastPunchline ] = []

                            }
                            punchcard[ lastPunchline ].push( row )
                        }
                    }

                    compiledPunchcard = this.compile( punchcard )
                    for( let c in compiledPunchcard )
                    {
                        let rows = compiledPunchcard[ c ]
                        for( let r in rows )
                        {
                            if( false === this.punchcards.activeMonth )
                            {
                                this.punchcards.activeMonth = card
                                this.punchcards.activeMonthFormatted = 10 < card ? '0'+card : card
                                this.punchcards.activeCard = c
                                this.punchcards.activeLine = r
                            }
                        }
                    }

                    if( card === this.punchcards.activeMonth )
                    {
                        let index = Object.keys( compiledPunchcard ).length - 1
                        this.punchcards.activeCard = Object.keys( compiledPunchcard )[ index ]
                        this.punchcards.activeLine = compiledPunchcard[ this.punchcards.activeCard ].length - 1
                    }

                    this.punchcards.lines[ card ] = {}
                    this.punchcards.lines[ card ].grandTotal = {
                        pause: 0,
                        work : 0,
                        diff : 0
                    }
                    this.punchcards.lines[ card ].totals = {}

                    this.punchcards.lines[ card ].rows = compiledPunchcard

                    for( let i in Object.keys( compiledPunchcard ) )
                    {

                        let id  = Object.keys( compiledPunchcard )[ i ],
                            row = compiledPunchcard[ id ]
                        this.punchcards.lines[ card ].totals[ id ] = this.prepareTotals( card, row )

                    }

                    return resolve()

                } )

        } )

    }

    getPunchlines( cards )
    {

        return new Promise( resolve =>
        {

            if( 0 < cards.length )
            {

                let card = cards.shift()
                this.getPunchlinesForCard( card )
                    .then( () =>
                    {

                        return resolve( this.getPunchlines( cards ) )

                    } )

            }
            else
            {
                return resolve()
            }

        } )

    }

    getPunchcards()
    {

        return new Promise( resolve =>
        {

            this.client
                .request( {
                    action: 'punchlines.getPunchcards'
                } )
                .then( response =>
                {

                    if( !this.cardsInitialized )
                    {
                        this.logger.clog( 'Punchcards:getPunchcards', 'fetching punchcards from remote...' )
                        if( Array.isArray( response.rows ) )
                        {
                            let cards = []
                            for( let r in response.rows )
                            {
                                cards.push( response.rows[ r ].months )
                            }
                            this.punchcards.cards = cards
                        }
                        this.cardsInitialized = true
                    }

                    this.getPunchlines( JSON.parse( JSON.stringify( this.punchcards.cards ) ) )
                        .then( () =>
                        {

                            this.ready = true
                            return resolve()

                        } )

                } )

        } )

    }

    hasActivePunchcard()
    {

        let now        = new Date(),
            activeCard = this.punchcards.lines[ this.punchcards.activeMonth ]

        if( undefined === activeCard )
        {
            return false
        }

        if( undefined === this.punchcards.lines[ this.punchcards.activeMonth ].rows
            || undefined === this.punchcards.lines[ this.punchcards.activeMonth ].rows[ this.punchcards.activeCard ]
            || undefined === this.punchcards.lines[ this.punchcards.activeMonth ].rows[ this.punchcards.activeCard ][ this.punchcards.activeLine ] )
        {
            return false
        }

        let activeLine = this.punchcards.lines[ this.punchcards.activeMonth ].rows[ this.punchcards.activeCard ][ this.punchcards.activeLine ]

        if( !activeLine.closed )
        {
            return true
        }

        let test = new Date( this.punchcards.lines[ this.punchcards.activeMonth ].rows[ this.punchcards.activeCard ][ this.punchcards.activeLine ].from )

        if( test.getFullYear() === now.getFullYear()
            && test.getMonth() === now.getMonth()
            && test.getDate() === now.getDate() )
        {
            return true
        }

        return false

    }

}