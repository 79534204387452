export default class Session
{
    constructor( core )
    {

        if( !Session.instance )
        {
            this.logger = core.getLogger()
            this.client = core.getClient()
            this.store = core.getStore()
            this.router = core.getRouter()

            this.checkStoredSession()

            this.sessionTimer = setInterval( () => {
                this.checkStoredSession()
            }, 30000 )

            Session.instance = this
        }

        return Session.instance

    }

    destruct()
    {
        clearInterval( this.sessionTimer )
    }

    checkStoredSession()
    {

        if( undefined !== this.store.getters.sessionId
            && null !== this.store.getters.sessionId )
        {

            this.client.request( {
                    AUTHENTICATION: Date.now(),
                    action        : 'checkSession',
                    id_session    : this.store.getters.sessionId
                } )
                .then( result =>
                {
                    if( 'error' === result.result
                        && 'E_SESSION_INVALID' === result.error )
                    {
                        this.logger.cerror( 'Session::checkStoredSession', 'invalid session id -> resetting...' )
                        this.store.dispatch( 'resetSession' )
                        this.router.push( '/' )
                    }
                } )

        }

    }

}