import { createApp } from 'vue'
import App           from './App.vue'
import router        from './router'
import { store }     from './store'
import CorePlugin    from '@/plugins/CorePlugin'

import './registerServiceWorker'

const app = createApp( {
    extends: App
} )
    .use( router )
    .use( store )
    .use( CorePlugin, store, router )

app.mount( '#app' )