import { createWebHashHistory, createRouter } from 'vue-router'

const Home = () => import('@/views/Home')
const About = () => import('@/views/About')
const Register = () => import('@/views/Register')
const Dashboard = () => import('@/views/Dashboard')
const Timetaker = () => import('@/views/Timetaker')
const Totals = () => import('@/views/Totals')
const Settings = () => import('@/views/Settings')

const routes = [
    {
        path     : '/',
        name     : 'home',
        component: Home
    },
    {
        path     : '/about',
        name     : 'about',
        component: About
    },
    {
        path     : '/dashboard',
        name     : 'dashboard',
        component: Dashboard
    },
    {
        path     : '/timetaker',
        name     : 'timetaker',
        component: Timetaker
    },
    {
        path     : '/totals',
        name     : 'totals',
        component: Totals
    },
    {
        path     : '/settings',
        name     : 'settings',
        component: Settings
    },
    {
        path     : '/register',
        name     : 'register',
        component: Register
    }
]

const router = createRouter( {
    routes,
    history: createWebHashHistory(),
    scrollBehavior( to, from, savedPosition )
    {
        if( to.hash )
        {
            return {
                selector: to.hash,
                behavior: 'smooth'
            }
        }
    }
} )

export default router