<template>
    <div class="clock">
        <div class="column">
            <div class="num">0</div>
            <div class="num">1</div>
            <div class="num">2</div>
        </div>
        <div class="column">
            <div class="num">0</div>
            <div class="num">1</div>
            <div class="num">2</div>
            <div class="num">3</div>
            <div class="num">4</div>
            <div class="num">5</div>
            <div class="num">6</div>
            <div class="num">7</div>
            <div class="num">8</div>
            <div class="num">9</div>
        </div>
        <div class="colon"></div>
        <div class="column">
            <div class="num">0</div>
            <div class="num">1</div>
            <div class="num">2</div>
            <div class="num">3</div>
            <div class="num">4</div>
            <div class="num">5</div>
        </div>
        <div class="column">
            <div class="num">0</div>
            <div class="num">1</div>
            <div class="num">2</div>
            <div class="num">3</div>
            <div class="num">4</div>
            <div class="num">5</div>
            <div class="num">6</div>
            <div class="num">7</div>
            <div class="num">8</div>
            <div class="num">9</div>
        </div>
        <div class="colon"></div>
        <div class="column">
            <div class="num">0</div>
            <div class="num">1</div>
            <div class="num">2</div>
            <div class="num">3</div>
            <div class="num">4</div>
            <div class="num">5</div>
        </div>
        <div class="column">
            <div class="num">0</div>
            <div class="num">1</div>
            <div class="num">2</div>
            <div class="num">3</div>
            <div class="num">4</div>
            <div class="num">5</div>
            <div class="num">6</div>
            <div class="num">7</div>
            <div class="num">8</div>
            <div class="num">9</div>
        </div>
    </div>
</template>

<script>
export default {

    name: 'Clock',

    mounted()
    {
        this.initialize()
    },

    data()
    {
        return {
            size           : 45,
            columns        : [],
            clockBoundaries: null,
            d              : null,
            c              : null,
            classList      : [ 'visible', 'close', 'far', 'far', 'distant', 'distant' ],
            use24HourClock : true,
            loop           : null
        }
    },

    beforeDestroy()
    {
        clearTimeout( this.loop )
    },

    methods: {

        initialize()
        {

            this.columns = Array.from( document.getElementsByClassName( 'column' ) )
            this.clockBoundaries = document.querySelector( '.clock' )
            let clockHeight = this.clockBoundaries.offsetHeight

            this.loop = setInterval( () =>
            {

                this.d = new Date()
                this.c = this.getClock()
                this.columns.forEach( ( ele, i ) =>
                {

                    let n = +this.c[ i ]
                    let offset = ( ( n * -1 ) * this.size )

                    ele.style.transform = 'translateY( calc( '+( clockHeight * 0.5 )+'px + ( ' + offset + 'px - ' + ( this.size / 2 ) + 'px ) ) )'
                    Array.from( ele.children ).forEach( ( ele2, i2 ) =>
                    {
                        ele2.className = 'num ' + this.getClass( n, i2 )
                    } )
                } )
            }, 500 )

        },

        padClock( p, n )
        {
            return p + ( '0' + n ).slice( -2 )
        },

        getClock()
        {

            return [
                this.use24HourClock ? this.d.getHours() : ( this.d.getHours() % 12 || 12 ),
                this.d.getMinutes(),
                this.d.getSeconds()
            ].reduce( this.padClock, '' )
        },

        getClass( n, i2 )
        {
            return this.classList.find( ( class_, classIndex ) => Math.abs( n - i2 ) === classIndex ) || ''
        }

    }

}
</script>