export default class Translate
{
    constructor( core )
    {

        if( !Translate.instance )
        {

            this.set = this.init()
            Translate.instance = this

        }

        return Translate.instance

    }

    init()
    {
        return {
            'month-01': 'Januar',
            'month-02': 'Februar',
            'month-03': 'März',
            'month-04': 'April',
            'month-05': 'Mai',
            'month-06': 'Juni',
            'month-07': 'Juli',
            'month-08': 'August',
            'month-09': 'September',
            'month-10': 'Oktober',
            'month-11': 'November',
            'month-12': 'Dezember',
            'day-0'   : 'Sonntag',
            'day-1'   : 'Montag',
            'day-2'   : 'Dienstag',
            'day-3'   : 'Mittwoch',
            'day-4'   : 'Donnerstag',
            'day-5'   : 'Freitag',
            'day-6'   : 'Samstag'

        }
    }

    translate( key )
    {

        if( undefined === this.set[ key ] )
        {
            return 'MISSING_TRANSLATION_' + key
        }
        return this.set[ key ]

    }

}