<template>
    <template v-if="fullscreen">
        <div class="spinner-wrapper-fullscreen">
            <div class="spinner"></div>
        </div>
    </template>
    <template v-else>
        <div class="spinner-wrapper inline">
            <div class="spinner"></div>
        </div>
    </template>
</template>

<script>
export default {

    name: 'Spinner',
    props: {
        fullscreen: { type: Boolean, required: false, default: false }
    }

}
</script>