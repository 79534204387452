<template>
    <div id="nav">
        <div class="items">
            <template v-if="null !== $store.getters.sessionId">
                <router-link @click="resetPunchcardEditor" to="/timetaker">Zeiterfassung</router-link>
                <router-link @click="resetPunchcardEditor" to="/dashboard">Stempelkarten</router-link>
                <router-link @click="resetPunchcardEditor" to="/totals">Jahresübersicht</router-link>
                <router-link @click="resetPunchcardEditor" to="/settings">Einstellungen</router-link>
            </template>
            <template v-else>
                <router-link @click="resetPunchcardEditor" to="/">Startseite</router-link>
                <router-link @click="resetPunchcardEditor" to="/about">About</router-link>
            </template>
        </div>
        <LoginBox/>
    </div>
</template>

<script>
import LoginBox from '@/components/partials/LoginBox'

export default {
    name      : 'Navigation',
    components: { LoginBox },

    methods: {
        resetPunchcardEditor()
        {

            this.$core.setState( 'punchcardEditorOpen', false )

        }
    }
}
</script>