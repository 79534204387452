<template>
    <form>
        <div class="form dark login">
            <div class="row dark">
                <div class="field full">
                    <input type="text"
                           id="username"
                           autocomplete="username"
                           placeholder="Dein Benutzername"
                           name="username"/>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="row dark">
                <div class="field full">
                    <input type="password"
                           id="password"
                           autocomplete="current-password"
                           placeholder="Dein Passwort"
                           name="password"/>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="row dark">
                <div class="field full">
                    <input type="submit" value="anmelden" @click="handleSubmit"/>
                    <a @click="$emit('hide')" class="small centered full" href="/#/register">Neu hier?</a>
                    <a @click="$emit('hide')" class="small centered full" href="/#/password-reset">Passwort
                        vergessen?</a>
                </div>
                <div class="clearfix"></div>
            </div>
            <template v-if="!ready">
                <Spinner/>
            </template>
        </div>
    </form>
</template>

<script>
import Spinner from '@/components/layout/Spinner'

export default {

    name      : 'LoginForm',
    components: { Spinner },
    emits     : [ 'hide' ],

    data()
    {
        return {
            ready : true,
            errors: false
        }
    },

    methods: {
        handleSubmit( event )
        {

            event.preventDefault()
            event.stopPropagation()

            let username = document.querySelector( '#username' ).value,
                password = document.querySelector( '#password' ).value

            this.ready = false
            this.errors = true

            this.$core
                .getClient()
                .request( {
                    AUTHENTICATION: Date.now(),
                    action        : 'login',
                    username      : username,
                    password      : password
                } )
                .then( result =>
                {
                    if( 'success.' === result.result
                        && undefined !== result.id_session )
                    {
                        this.$store.commit( 'setSessionId', result.id_session )
                        this.$store.commit( 'setSessionData', {
                            username: username.trim()
                        } )

                        this.$core.getSettings()
                            .initializeSettings()

                        this.$emit( 'hide' )
                        this.$router.push( 'timetaker' )
                    }
                    else
                    {
                        this.errors = true
                    }
                } )

            setTimeout( () =>
            {
                this.ready = true
            }, 2500 )

        }
    }

}
</script>