<template>
    <div id="header">
        <Clock/>
        <div class="logo" @click="forceReload"></div>
        <Navigation />
    </div>
</template>
<script>
import Clock      from '@/components/layout/Clock'
import Navigation from '@/components/layout/Navigation'

export default {

    name      : 'Header',
    components: { Navigation, Clock },

    methods: {
        forceReload()
        {
            document.location.reload( true )
        }
    }

}
</script>