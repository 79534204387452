import { createStore } from 'vuex'

export const store = createStore( {

    state()
    {
        return {
            connected  : false,
            sessionId  : null,
            sessionData: {}
        }
    },

    mutations: {
        setConnected( state, value )
        {
            state.connected = value
        },
        setSessionId( state, value )
        {
            state.sessionId = value
            if( null !== value )
            {
                localStorage.setItem( 'sessionId', value )
            }
            else
            {
                localStorage.removeItem( 'sessionId' )
            }
        },
        setSessionData( state, value )
        {
            state.sessionData = value
            if( null !== value )
            {
                localStorage.setItem( 'sessionData', JSON.stringify( value ) )
            }
            else
            {
                localStorage.removeItem( 'sessionData' )
            }
        },
        initStates( state )
        {

            let todo = [
                'sessionId',
                'sessionData'
            ]

            for( let t in todo )
            {
                let value = localStorage.getItem( todo[ t ] )
                if( null !== value )
                {
                    if( 'sessionData' === todo[t] )
                    {
                        state[ todo[ t ] ] = JSON.parse( value )
                    }
                    else
                    {
                        state[ todo[ t ] ] = value
                    }
                }
            }

        }
    },

    getters: {
        connected  : state => state.connected,
        sessionId  : state => state.sessionId,
        sessionData: state => state.sessionData
    },

    actions: {
        resetSession( { commit } )
        {
            return new Promise( resolve => {

                commit( 'setSessionId', null )
                commit( 'setSessionData', null )

                return resolve()

            })
        }
    }

} )