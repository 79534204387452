export default class Settings
{
    constructor( core )
    {

        if( !Settings.instance )
        {
            this.logger = core.getLogger()
            this.client = core.getClient()
            this.store = core.getStore()
            this.router = core.getRouter()

            this.settings = {}
            this.ready = false

            this.initializeSettings()

            Settings.instance = this
        }

        return Settings.instance

    }

    isReady()
    {
        return new Promise( resolve =>
        {
            if( this.ready )
            {
                return resolve()
            }
            setTimeout( () =>
            {
                return resolve( this.isReady() )
            }, 500 )
        } )
    }

    get( key )
    {
        return this.settings[ key ]
    }

    set( key, value )
    {
        this.settings[ key ] = value
        this.storeRemote()
    }

    storeRemote()
    {
        this.client.request( {
                AUTHENTICATION: Date.now(),
                action        : 'settings.store',
                settings      : JSON.stringify( this.settings )
            } )
            .then( response =>
            {
                console.log( response )
            } )
    }

    write( values )
    {
        this.settings = values
        this.storeRemote()
    }

    initializeSettings()
    {

        if( undefined !== this.store.getters.sessionId
            && null !== this.store.getters.sessionId )
        {

            this.client.request( {
                    AUTHENTICATION: Date.now(),
                    action        : 'settings.get'
                } )
                .then( result =>
                {
                    if( 'error' !== result.result
                        && undefined !== result.settings )
                    {
                        this.settings = JSON.parse( result.settings )
                        this.ready = true
                    }
                    else
                    {

                        this.set( 'workdays', [ 1, 2, 3, 4, 5 ] )
                        this.set( 'hours-general', 40 )
                        this.set( 'pause-real', true )
                        this.ready = true

                    }

                } )

        }

    }

}