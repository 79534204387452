<template>
    <div class="login-box">
        <template v-if="null === $store.getters.sessionId">
            <div class="info" @click="formVisible = !formVisible">
                Login
            </div>
        </template>
        <template v-else>
            <div class="info" @click="logout">
                {{ $store.getters.sessionData.username }} abmelden
            </div>
        </template>
    </div>
    <transition appear name="next">
        <template v-if="true === formVisible">
            <div class="login-form-wrapper">
                <LoginForm @hide="formVisible = false"/>
            </div>
        </template>
    </transition>
</template>

<script>
import LoginForm from '@/components/partials/LoginForm'
export default {
    name: 'LoginBox',
    components: { LoginForm },

    data()
    {
        return {
            formVisible: false
        }
    },

    methods:
    {
        logout()
        {
            this.$store.dispatch( 'resetSession' )
                .then( () => {
                    this.$router.push( '/' )
                })
        }
    }
}
</script>