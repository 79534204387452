<template>
    <Header/>
    <div id="content">
        <router-view v-slot="{ Component }">
            <transition appear name="fade" mode="out-in">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
    <div id="footer">
        &copy;2010-{{ year }} <strong>timetake.de</strong> | Version {{ $core.version }} | Kontakt | Impressum
    </div>
</template>

<style lang="less">
@import '~@/assets/fonts/alegreya/alegreya.less';
@import '~@/assets/less/layout/device-limits.less';
@import '~@/assets/less/definitions.less';
@import '~@/assets/less/layout/clock.less';
@import '~@/assets/less/partials/punchcard.less';
</style>
<script>
import Header from '@/components/layout/Header'

export default {
    components: { Header },
    computed  : {
        year()
        {
            return new Date().getFullYear()
        }
    }
}
</script>