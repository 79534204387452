import Ajax       from '@/classes/subs/Ajax'
import Logger     from '@/classes/subs/Logger'
import Session    from '@/classes/subs/Session'
import Translate  from '@/classes/subs/Translate'
import Settings   from '@/classes/subs/Settings'
import Punchcards from '@/classes/subs/Punchcards'

export default class Core
{

    constructor( store, router )
    {
        if( !Core.instance )
        {

            this.store = store
            this.store.commit( 'initStates' )

            this.states = new Map()

            this.router = router
            this.logger = new Logger()
            this.ajax = new Ajax( this )
            this.session = new Session( this )
            this.settings = new Settings( this )
            this.translation = new Translate( this )
            this.punchcards = new Punchcards( this )

            this.version = '2.0.0-rc1'

            Core.instance = this

        }
        return Core.instance
    }

    t( key )
    {
        return this.translation.translate( key )
    }

    s( key )
    {
        return this.settings.get( key )
    }

    pc()
    {
        return this.punchcards
    }

    setState( key, value )
    {
        this.states.set( key, value )
    }

    getState( key )
    {
        return this.states.get( key )
    }

    getClient()
    {
        return this.ajax
    }

    getLogger()
    {
        return this.logger
    }

    getStore()
    {
        return this.store
    }

    getRouter()
    {
        return this.router
    }

    getRemoteSession()
    {
        return this.session
    }

    getSettings()
    {
        return this.settings
    }
}