export default class Ajax
{

    constructor( core )
    {

        if( !Ajax.instance )
        {

            this.axios = require( 'axios' )
            this.store = core.getStore()
            this.logger = core.getLogger()
            this.logger.cconstructed( 'Ajax', 'class operational' )
            this.client = this.createClient()

            this.ping()
            this.pingTimer = setInterval( () => {
                this.ping()
            }, 5000 )

            Ajax.instance = this

        }

        return Ajax.instance

    }

    destruct()
    {
        clearInterval( this.pingTimer )
        delete this.client
        delete Ajax.instance
    }

    createClient()
    {
        return this.axios.create({
            baseURL: 'https://core.timetake.de'
        })
    }

    ping()
    {

        const params = new URLSearchParams({ PING: 'ping' });

        this.client.post( '/', params ).then( result => {

            if( 200 === result.status && 'pong' === result.data )
            {
                this.store.commit( 'setConnected', true )
            }
            else
            {
                this.store.commit( 'setConnected', false )
            }
        })

    }

    request( params )
    {

        return new Promise( ( resolve, reject ) => {

            if( null !== this.store.getters.sessionId )
            {
                params.SESSION_ID = this.store.getters.sessionId
                params.id_session = this.store.getters.sessionId
            }

            const req = new URLSearchParams( params )

            this.client.post( '/', req ).then( result => {

                if( 200 === result.status
                    && '.' !== result.data )
                {
                    return resolve( result.data )
                }
                else
                {
                    this.logger.cerror( 'Ajax::request', 'status: '+result.status, 'message: '+result.data )
                    return reject()
                }

            })

        } )

    }

}